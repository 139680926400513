<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn class="gray font-weight-bold" @click="onBack"
          ><v-icon left>mdi-arrow-left-circle</v-icon>戻る</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn class="info font-weight-bold" @click="onSubmit"
          ><v-icon left>mdi-content-save</v-icon>保存</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn class="info font-weight-bold" @click="onSync"
          ><v-icon left>mdi-sync</v-icon>保存して更新</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="pa-2">
        <v-btn class="info font-weight-bold" @click="getSort"
          ><v-icon left>mdi-cloud-download-outline</v-icon
          ><span class="d-none d-sm-flex">並び順取込</span
          ><span class="d-sm-none">取込</span></v-btn
        >
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(props, ctx) {
    const onBack = async () => {
      ctx.emit("on_back");
    };

    const onSubmit = async () => {
      ctx.emit("on_submit");
    };

    const onSync = async () => {
      ctx.emit("on_sync");
    };

    const getSort = async () => {
      ctx.emit("get_sort");
    };

    return {
      props,
      onBack,
      onSubmit,
      onSync,
      getSort,
    };
  },
});
</script>
