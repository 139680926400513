<template>
  <div>
    <app-bar
      ref="refAppBar"
      @on_back="onBack"
      @on_submit="onSubmit"
      @on_sync="onSync"
      @get_sort="getSort"
    ></app-bar>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      キャスト画像をマウスでドラッグして並び替えてください
    </div>
    <draggable
      v-model="castList"
      v-bind="dragOptions"
      handle=".hundle_img"
      :force-fallback="true"
      class="d-flex flex-wrap"
    >
      <div
        v-for="cast in castList"
        :key="cast.id"
        class="image_card ma-2 hundle_img"
      >
        <div class="work_label">
          <div v-show="cast.today_label" :class="cast.today_label_class">
            {{ cast.today_label }}
          </div>
          <div v-show="cast.tomorrow_label" class="tomorrow">
            {{ cast.tomorrow_label }}
          </div>
        </div>
        <div v-show="!cast.is_enable" class="white_back"></div>
        <v-card loading="false" outlined tile width="167">
          <v-img
            height="220px"
            :src="cast.image ? setting.cdn + cast.image : ''"
            v-if="cast.image"
          >
          </v-img>
          <v-img
            height="220px"
            src="@/assets/images/no_image.jpg"
            v-else
          ></v-img>
          <div class="image_info pa-1">
            {{ cast.name }}
          </div>
          <div v-show="!cast.is_enable" class="image_info_enable pa-1 mini">
            [非公開中]
          </div>
        </v-card>
      </div>
    </draggable>

    <!-- 取込ダイアログ -->
    <v-dialog v-model="syncDialog" scrollable persistent width="1000px">
      <v-card :loading="syncDisabled" :disabled="syncDisabled">
        <v-card-title>
          <span class="text-h5">並び順取込</span>
        </v-card-title>
        <v-card-text>
          <h3 id="cast_sync_step1">取込サイト選択</h3>
          <div class="mt-2 mb-2">
            キャストの並び順を取り込む媒体を選択してください。現在設定されている並び順は上書きされます。
          </div>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  prepend-icon="mdi-web"
                  v-model="syncShopSiteId"
                  :items="castSortSiteListReadOnly"
                  item-value="uk"
                  item-text="site_name"
                  no-data-text="更新管理にて媒体を登録して下さい"
                  label="媒体選択"
                  success
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  :btn_disabled="!castSortSiteList"
                  @click-event="onSyncSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-cloud-download-outline</v-icon></template
                  >取込</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 一括更新ダイアログ -->
    <v-dialog
      v-model="castSortDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card :disabled="castSortDialogDisabled">
        <v-card-title>
          <span class="text-h5">キャストの並び順を更新</span>
        </v-card-title>
        <v-card-text>
          <v-subheader class="pl-0">
            並び順の更新処理を行います。更新したい媒体にチェックして下さい。
          </v-subheader>
          <h3>媒体を選択</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- テーブル -->
                <v-data-table
                  :headers="castSortHeaders"
                  :items="castSortSiteList"
                  :mobile-breakpoint="0"
                  item-key="uk"
                  show-select
                  :single-select="false"
                  v-model="castSortSelected"
                  :footer-props="{ 'items-per-page-options': [-1] }"
                  hide-default-footer
                  @click:row="rowClick"
                >
                  <template v-slot:no-data>
                    表示するデータがありません
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    &nbsp;
                    <v-progress-linear
                      v-if="item.status == '更新中'"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button btn_color="gray" btn_block @click-event="onBack"
                  ><template v-slot:icon
                    ><v-icon left>mdi-arrow-left-circle</v-icon></template
                  >一覧に戻る</general-button
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelCastSortDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitCastSortDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-sync</v-icon></template
                  >一括更新</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import draggable from "vuedraggable";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

import appBar from "./appBar.vue";
import { stat } from "fs";
import internal from "stream";

export default defineComponent({
  components: {
    appBar,
    draggable,
  },
  props: ["cast_id"],
  setup(props, ctx) {
    const CastsRepository = repositoryFactory.get("casts");
    const SynchroRepository = repositoryFactory.get("synchro");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const { router } = useRouter();
    const refAppBar = ref();

    const state = reactive({
      castList: [],
      castSortSiteList: [],
      castSortSelected: [],
      castSortSiteListReadOnly: [],
      // 取込ボタン
      syncDialog: false,
      syncDisabled: false,
      syncShopSiteId: 0,
      // 一括更新ボタン関連
      castSortDialog: false,
      castSortDialogDisabled: false,
      castSortHeaders: [
        {
          text: "サイト名",
          value: "site_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
        },
      ],
    });

    const init = async () => {
      await store.dispatch("loadingIcon/showIcon");
      const shop_dateline = store.getters["shops/dateline"];
      let today = utilDate.getDateFormatLong(
        utilDate.getToday(0, shop_dateline)
      );
      let tomorrow = utilDate.getDateFormatLong(
        utilDate.getToday(1, shop_dateline)
      );
      const now = utilDate.convert24TimeStart(
        utilDate.getTimeFormat(new Date()),
        shop_dateline
      );

      state.castList.splice(0);

      // システム内の登録キャストを取得する
      const shop_id = await store.getters["shops/currentShop"].id;
      let params = {
        shop_id: shop_id,
        start_date: today,
        end_date: tomorrow,
      };
      state.castList.splice(0);
      await CastsRepository.list_with_schedule(params)
        .then((response) => {
          if (response.data) {
            // データ格納
            Object.keys(response.data).forEach(function (key) {
              let today_label = null;
              let today_label_class = null;
              let tomorrow_label = null;
              for (
                let i = 0;
                i < response.data[key].schedule_list.length;
                i++
              ) {
                if (
                  response.data[key].schedule_list[i].start_time == null ||
                  response.data[key].schedule_list[i].end_time == null
                ) {
                  continue;
                }
                if (today == response.data[key].schedule_list[i].work_date) {
                  const s_time = utilDate.convert24TimeStart(
                    response.data[key].schedule_list[i].start_time
                  );
                  const e_time = utilDate.convert24TimeStart(
                    response.data[key].schedule_list[i].end_time
                  );
                  if (s_time <= now && e_time > now) {
                    today_label =
                      "出勤中[-" +
                      response.data[key].schedule_list[i].end_time +
                      "]";
                    today_label_class = "working";
                  } else if (s_time > now) {
                    today_label =
                      "本日出勤[" +
                      response.data[key].schedule_list[i].start_time +
                      "-" +
                      response.data[key].schedule_list[i].end_time +
                      "]";
                    today_label_class = "before";
                  } else if (e_time < now) {
                    today_label = "勤務終了";
                    today_label_class = "after";
                  }
                }
                if (tomorrow == response.data[key].schedule_list[i].work_date) {
                  tomorrow_label =
                    "明日出勤[" +
                    response.data[key].schedule_list[i].start_time +
                    "-" +
                    response.data[key].schedule_list[i].end_time +
                    "]";
                }
              }
              state.castList.push({
                id: response.data[key].id,
                name: response.data[key].name,
                image: response.data[key].image,
                is_enable: response.data[key].is_enable,
                today_label: today_label,
                today_label_class: today_label_class,
                tomorrow_label: tomorrow_label,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:Schedule/ScheduleList/index.vue/init CastsRepository.list_with_schedule (" +
            error +
            ")"
          );
        });

      // 更新サイト一覧取得
      state.castSortSiteList.splice(0);
      state.castSortSelected.splice(0);
      params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getcast_update(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const data = {
                uk: response.data[key].id, // shop_site_id
                site_content_id: response.data[key].site_content_id,
                site_id: response.data[key].site_id,
                site_name:
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                status: null,
              };
              state.castSortSiteList.push(data);
              state.castSortSelected.push(data);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });
      await ShopSitesRepository.list_getcast(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const data = {
                uk: response.data[key].id, // shop_site_id
                site_content_id: response.data[key].site_content_id,
                site_name:
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                status: null,
              };
              state.castSortSiteListReadOnly.push(data);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });
      if (state.castSortSiteListReadOnly.length > 0) {
        state.syncShopSiteId = state.castSortSiteListReadOnly[0].uk;
      }

      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    // 並び替え保存処理
    const save = async (isSync = false) => {
      if (isSync) {
        if (
          !window.confirm(
            "現在の並び順を保存し、その後、各媒体に更新します。よろしいですか？"
          )
        ) {
          return false;
        }
      } else {
        if (!window.confirm("現在の並び順を保存します。よろしいですか？")) {
          return false;
        }
      }

      store.dispatch("loadingIcon/showIcon");

      //保存処理
      const sort_list = [];
      let sort = 1;
      for (let index = 0; index < state.castList.length; index++) {
        // console.log(state.castList[index]);
        sort_list.push({ "id": state.castList[index].id, "sort": sort });
        sort++;
      }
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        sort_list: sort_list,
      };
      // console.log(params);
      await CastsRepository.save_sort(params)
        .then((response) => {
          if (response.data) {
            // データ格納
          }
        })
        .catch((error) => {
          throw (
            "ERROR:castSort/index.vue/save CastsRepository.sort_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon");

      return true;
    };

    // キャスト一覧に戻る
    const onBack = async () => {
      router.push({ name: "cast" });
    };

    // 保存ボタン
    const onSubmit = async () => {
      await save(false);
    };

    // 取込ボタン
    const getSort = async () => {
      state.syncDialog = true;
    };

    // 並び順取込
    const onSyncSubmit = async () => {
      if (state.syncShopSiteId == 0) {
        alert("取り込む媒体を選択してください。");
        return false;
      }
      if (
        !window.confirm(
          "選択した媒体から全キャストの並び順を取り込みます。よろしいですか？\n※現在、登録されている並び順は上書きされます。\n　媒体に登録されていないキャストが居た場合、並び順が最後になります。"
        )
      ) {
        return false;
      }
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.syncDisabled = true;

      // 並び順取込
      let result = true;
      let params = {
        shop_id: store.getters["shops/currentShop"]["id"],
        shop_site_id: state.syncShopSiteId,
      };
      result = await SynchroRepository.get_cast_order(params)
        .then((response) => {
          if (response.data) {
            if (response.data.status) {
              return true;
            }
          } else {
            alert(response.data.message);
            return false;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:cstSort/index.vue/onSyncSubmit SynchroRepository.get_cast_order (" +
            error +
            ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.syncDisabled = false;

      // ダイアログ閉じる
      state.syncDialog = false;

      if (result) {
        alert("並び順の取り込みが完了しました。");
        router.go({ path: router.currentRoute.path, force: true });
      } else {
        alert("並び順の取り込みに失敗しました。");
      }
    };

    // ダイアログキャンセルボタン
    const cancelSyncDialog = () => {
      state.syncDialog = false;
    };

    const castSortButton = async () => {
      // ラベル表示クリア
      for (let i = 0; i < state.castSortSiteList.length; i++) {
        set(state.castSortSiteList[i], "status", "");
      }
      state.castSortDialog = true;
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const cancelCastSortDialog = async () => {
      state.castSortDialog = false;
    };

    const onSubmitCastSortDialog = async () => {
      // ここに更新処理
      if (
        !window.confirm(
          "選択したコンテンツを更新を開始します。よろしいですか？"
        )
      ) {
        return false;
      }

      // ラベル表示クリア
      for (let i = 0; i < state.castSortSiteList.length; i++) {
        set(state.castSortSiteList[i], "status", "");
      }
      // 順次更新処理を行う
      state.castSortDialogDisabled = true;
      store.dispatch("loadingIcon/showIcon");

      // ラベル表示
      for (let i = 0; i < state.castSortSelected.length; i++) {
        set(state.castSortSelected[i], "status", "処理待ち");
      }
      // 順次処理する
      for (let i = 0; i < state.castSortSelected.length; i++) {
        set(state.castSortSelected[i], "status", "更新中");

        // 更新処理
        if (
          setting.cast_sort_disable_site_ids.find(
            (v) => v === state.castSortSelected[i].site_id
          )
        ) {
          set(state.castSortSelected[i], "status", "非対応のためスキップ");
        } else {
          let params = new URLSearchParams();
          params = {
            shop_id: store.getters["shops/currentShop"]["id"],
            shop_site_id: state.castSortSelected[i].uk,
          };
          let result = false;
          // console.log(params);

          // キャストを更新する
          result = await SynchroRepository.sync_cast_order(params)
            .then((response) => {
              if (response.data) {
                if (response.data.status) {
                  return true;
                }
              } else {
                alert(response.data.message);
                return false;
              }
            })
            .catch((error) => {
              throw (
                "ERROR:castSort/index.vue/onSubmitCastSortDialog SynchroRepository.sync_cast_order (" +
                error +
                ")"
              );
            });

          if (result) {
            set(state.castSortSelected[i], "status", "更新完了");
          } else {
            set(state.castSortSelected[i], "status", "更新失敗");
          }
        }
      }

      store.dispatch("loadingIcon/hideIcon");
      state.castSortDialogDisabled = false;

      alert("更新処理が完了しました");
    };

    // キャストを更新
    const onSync = async () => {
      // 保存処理
      const result = await save(true);

      // 更新処理
      if (result) {
        await castSortButton();
      }
    };

    return {
      dragOptions: {
        animation: 200,
        group: "cast",
        disabled: false,
        ghostClass: "ghost",
      },
      props,
      setting,
      utilDate,
      refAppBar,
      ...toRefs(state),
      save,
      onBack,
      onSubmit,
      onSync,
      getSort,
      cancelSyncDialog,
      onSyncSubmit,
      rowClick,
      castSortButton,
      cancelCastSortDialog,
      onSubmitCastSortDialog,
    };
  },
});
</script>

<style scoped>
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_card:hover {
  cursor: grab;
}

.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 4;
}
.image_info {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 4;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.image_info_enable {
  position: absolute;
  bottom: 32px;
  right: 0px;
  z-index: 4;
  width: 100%;
  color: yellow;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
}
.white_back {
  background-color: rgba(255, 255, 255, 0.5);
  height: 220px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.work_label {
  font-size: 0.8em;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  color: white;
}
.before {
  background-color: rgba(0, 255, 0, 0.5);
}
.working {
  background-color: rgba(0, 0, 255, 0.5);
}
.after {
  background-color: rgba(0, 0, 0, 0.5);
}
.tomorrow {
  background-color: rgba(200, 100, 100, 0.5);
}
</style>
